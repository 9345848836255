.mainHomeTab {
    text-align: center;
    padding: 1rem 2rem !important;
    cursor: pointer !important;
    border: 1px solid #ddd !important;
    width: 9rem !important;
    font-weight: 500;
    transition: all 0.15s ease;
}

.mainHomeTab:hover {
    background-color: rgba(0,0,0,0.03) !important;
}

.outerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50rem;
    max-width: 100%;
    border-radius: 10px;
    cursor: pointer;
    padding: 1rem;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.01);
    transition: all 0.15s ease;
}

.cardWrapper:hover {
    background-color: rgba(0,0,0,0.03);
}

.cardWrapperUserDetails {
    display: flex;
}

.leftSection {
    display: flex;
    flex-direction: column;
    width: 65%;
    overflow: hidden;
}

.rightSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    color: #1e684f;
}

.cardDetailSpan {
    padding: 0.5rem;
}

.cardDetailsLabel {
    color: #999;
}

.cardDetailsData {
    font-weight: 500;
}

.cardDetailsPrice {
    display: block;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
    border-radius: 30px;
    background-color: #d9ebe3;
}

.cardDetailsPackagesContainer {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0.5rem;
}

.cardDetailsPackagesContainer:first-of-type {
    padding-top: 0.5rem;
}

.cardDetailsPackagesContainer:last-of-type {
    padding-bottom: 0;
}

.show {
    display: block !important;
}

.cardDetailsPackagePrice {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    background-color: #efdad7;
    background-color: #f2aa6b;
    color: #7e4f4b;
    color: #fff;
    min-width: 5rem;
    text-align: center;
}

.cardDetailsBookNowBtn {
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    background-color: #eee;
    cursor: pointer;
    transition: all 0.15s ease;
}

.cardDetailsBookNowBtn:hover {
    background-color: #bbb;
    color: #fff;
}

.downArrow {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.cardAvatarWrapper {
    height: 5rem;
    width: 5rem;
    border: 1px solid #eee;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
}

.cardAvatarImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dynamicPriceInput {
    border: 1px solid #ddd;
    border-radius: 50px;
    padding: 0.3rem 0.8rem;
}

.dynamicPriceInput:focus {
    outline: none;
}

@media(max-width:768px) {
    .cardWrapperUserDetails {
        flex-direction: column;
        gap: 0.5rem;
    }

    .cardDetailsPrice {
        border-radius: 20px;
    }

    .leftSection, .rightSection {
        width: 100%;
    }

    .rightSection {
        margin-bottom: 1rem;
    }

    .downArrow {
        display: block;
    }
}

@media(max-width:450px) {
    .cardDetailsBookNowBtn {
        font-size: 0.7rem;
    }
}

@media(max-width:350px) {
    .cardWrapper {
        padding: 0.5rem;
        font-size: 0.8rem;
    }

    .mainHomeTab {
        padding: 0.6rem 1.2rem;
    }
}

/* #d9ebe3, #1e684f */
/* #efdad7, #7e4f4b */