.bookEventPopupFormWrapper {
    position: absolute;
    padding: 1rem;
    border-radius: 5px;
    width: 21rem;
    max-width: 100%;
    box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.1);
    z-index: 100;
    background-color: #fff;
    display: none;
}

.bookEventPopupFormWrapper.show {
    display: block;
    animation: flashIn 0.15s
}

@keyframes flashIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.bookEventPopupFormInput {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eee;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.bookEventPopupFormInput:focus {
    outline: none;
}

.bookEventPopupDateTimeSelector {
    position: relative;
    display: inline-block;
    padding: 0.5rem;
    border-radius: 5px;
}

.bookEventPopupDateTimeSelector:hover {
    background-color: #eee;
}

.bookEventPopupTextarea {
    height: 5rem;
    width: 100%;
    resize: none;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    outline: none;
}

.bookEventPopupFormBtn {
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
    border: 1px solid #eee;
    font-size: 0.9rem;
    transition: all 0.15s ease;
    cursor: pointer;
}

.bookEventPopupFormBtn:hover {
    background-color: #eee;
}

.displayEventPopupField {
    margin-bottom: 1rem;
}

.rbc-event {
    /* flex-direction: row !important; */
    font-size: 0.9rem;
}