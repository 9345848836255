@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  color: #555;
}

.pointer {
  cursor: pointer;
}

.search-icon {
  font-size: 23px;
}

/* TOAST NOTIFICATION ****************************************************************************************** */
#toastContainer{
  /* border: 2px solid red; */
  position: fixed;
  top: 6rem;
  left: 2rem;
  width: auto;
  margin-right: 2rem;
  line-height: 1.5rem;
  z-index: 10;
}

.myToast{
  /* border: 2px solid green; */
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  background-color: #f6f8f8;
  border-radius: 5px;
  animation: fade-in .3s ease, fade-out .3s ease 3.8s;
}

@keyframes fade-in {
  from { opacity: 0 }
}
@keyframes fade-out {
  to { opacity: 0 }
}

.myToast.error{
  color: rgb(241, 66, 66);
}

.myToast.success{
  color: rgb(43, 173, 43);
}

.myToast.info{
  color: var(--text-color);
}