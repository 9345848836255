.mainWrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mainWrapper input {
    border-radius: 50px !important;
}

#suburb {
    margin-top: 0.5rem;
    border-radius: 10px;
    padding-top: 0;
    padding-bottom: 0;
    /* border: 2px solid red !important; */
}

#suburb::-webkit-scrollbar {
    /* border: 1px solid #eee !important; */
    background-color: #fff;
    border-radius: 10px;
    /* border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; */
}

/* #suburb::-webkit-scrollbar-button {
    border: 1px solid #eee;
    border-radius: 10px;
} */

#suburb::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
    min-height: 2rem;
    /* border: 1px solid pink; */
}

#suburb::-webkit-scrollbar-track {
    /* border: 1px solid pink; */
    border-radius: 10px;
    /* padding: 0.5rem !important; */
}

#suburb::-webkit-scrollbar-track-piece {
    /* border: 1px solid green;
    padding: 0.5rem !important; */
    border-radius: 10px;
}

.dropdown-item {
    /* border: 1px solid red; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

@media(max-width: 350px) {
    .formGroup {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: fit-content;
        margin: auto;
    }
}