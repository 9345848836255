.myEventsWrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
    min-height: 75vh;
}

.myEventsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.myEventsEventWrapper {
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 30%;
    background-color: #fff;
}

.myEventsLinks {
    display: block;
    color: #4db5aa;
    text-decoration: none !important;
}

.myEventsLinks:hover {
    color: #4db5aa !important;
}

@media(max-width:1100px) {
    .myEventsEventWrapper {
        flex-basis: 45%;
    }
}

@media(max-width:768px) {
    .myEventsEventWrapper {
        flex-basis: 70%;
    }
}

@media(max-width:500px) {
    .myEventsEventWrapper {
        flex-basis: 100%;
    }
}