.newEventWrapper {
    /* padding-left: 10rem;
    padding-right: 10rem; */
    width: 70%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.newEventContainer {
    border: 1px solid #eee;
    background-color: rgba(0,0,0,0.01);
    border-radius: 10px;
    padding: 2rem 5rem;
}

.newEventFormInput {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 0.7rem;
    width: 100%;
    margin-bottom: 1rem;
}

.newEventFormInput:focus {
    outline: 2px solid #d9ebe3;
}

.newEventMeetingTimeWrapper {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 1px solid #eee;
    padding-bottom: 0;
}

.meetingTimeHeading {
    margin-bottom: 1.8rem;
    margin-top: 0.5rem;
    text-align: center;
}

.newEventMeetingTimeDayWrapper {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    border-bottom: 1px solid #eee;
}

.newEventMeetingTimeDayWrapper:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
}

.newEventMeetingTimeDay {
    margin-right: 1rem;
    font-weight: 500;
    width: 2.5rem;
}

.meetingTimeAddBtnWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.newEventMeetingTime {
    border-radius: 5px;
    margin-right: 0.5rem;
    position: relative;
    border: 1px solid #eee;
    padding: 0.8rem 1.6rem;
    width: 8rem;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
}

.meetingTimeWrapper {
    cursor: pointer;
}

.newEventMeetingTimeUl {
    border: 1px solid #dadce0;
    position: absolute;
    top: 3.25rem;
    left: 0;
    height: 10rem;
    width: 110%;
    background-color: #fff;
    overflow: auto;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 10%);
    visibility: hidden;
    z-index: 10;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.newEventMeetingTimeLiActive {
    background-color: #eee;
}

.newEventMeetingTimeLi {
    padding: 0.5rem;
    padding-left: 1rem;
}

.meetingTimeHyphen {
    margin-right: 0.5rem;
}

.newEventMeetingTimeLi:hover {
    background-color: #eee;
}

.meetingTimeDeleteBtnWrapper, .meetingTimeAddBtnWrapper {
    cursor: pointer;
}

.text-small3 {
    font-size: 0.7rem;
}

.meetingTimeError {
    color: #df8383;
    position: absolute;
    bottom: 0;
    text-align: center;
    visibility: hidden;
}

.meetingTimeError.show {
    visibility: visible;
}

.selectOptionUlShow {
    visibility: visible !important;
}

.eventSubmitBtn {
    background-color: #eee;
    color: inherit;
    width: 100%;
    padding: 0.7rem;
}

.newEventContainer input[type="submit"]{
    margin: 1rem 0;
    margin-top: 1.2rem;
    border: none;
    font-weight: 700;
    cursor: pointer;
  }
  
  .newEventContainer input[type="submit"]:focus{
    outline: none;
  }
  
  .newEventContainer input[type="submit"]:disabled{
    cursor: default;
  }

@media(max-width:1000px) {
    .newEventContainer {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media(max-width: 768px) {
    .newEventWrapper {
        width: 100%;
    }
}

@media(max-width:500px) {
    .newEventMeetingTimeDayWrapper {
        flex-direction: column;
        gap: 0.5rem;
    }

    .newEventMeetingTimeDay, .newEventMeetingTime, .meetingTimeHyphen {
        margin-right: 0;
    }
}