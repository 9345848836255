.navMyEventsWrapper {
    position: relative;
    padding: 0.5rem;
    color: rgba(0,0,0,0.5);
    cursor: pointer;
    transition: all 0.15s ease;
}

.navMyEventsWrapper:hover {
    color: rgba(0,0,0,0.7);
}

.navMyEventsWrapper.active {
    color: rgba(0,0,0,0.7);
}

.navUl {
    display: none;
    position: absolute;
    top: 2.8rem;
    left: -1rem;
    width: 8rem;
    list-style-type: none;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #fff;
    padding: 0;
    margin: 0;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    z-index: 10;
    transition: all 0.15s ease;
}

.navUl.eventsNavShow {
    display: block;
}

.navUlLiA {
    display: block;
    padding: 0.5rem 1rem;
    transition: all 0.15s ease;
    text-decoration: none;
    color: inherit;
}

.navUlLiA:hover {
    background-color: #eee;
    color: rgba(0,0,0,0.7);
}

.navLoginButton {
    padding: 0.3rem 1rem;
    border: 1px solid #ddd;
    width: fit-content;
    border-radius: 5px;
    text-decoration: none;
    color: inherit;
    letter-spacing: 0.1rem;
    transition: all 0.15s ease-in-out;
}

.navLoginButton:hover {
    color: inherit;
    background-color: #eee;
}

#navbarScroll {
    flex-grow: 0;
}

@media(max-width:992px) {
    .navMyEventsWrapper {
        padding-left: 0;
    }

    .navUl {
        position: static;
        box-shadow: none;
        margin-top: 0.5rem;
    }
}